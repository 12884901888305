import React from 'react';
import Header from '../Home/header'
import Footer from '../Home/Footer'
function ContactUs() {
  return (
    <div>
            

            
          <Header/>
    <div className='herobg py-10 xl:py-20 h-auto'>
       <div className="w-11/12 2xl:max-w-[1198px] mx-auto">
           <div className="text-center">
               <h1 className='text-set f-f-r-no text-4xl sm:text-5xl lg:text-6xl text-primary'>Contact Us</h1>
             
           <div className="mt-10 text-center">

               <p className='text-white f-f-r-az mt-4'>We are currently onboarding a limited number of users. Please fill out the form below to schedule a demo.</p>
           </div>
           </div>
    <div className="py-10"></div>
    <div className="contact-us-page">
      <iframe 
        src="https://docs.google.com/forms/d/e/1FAIpQLSe9ihfN8yWv4ifLhSlIPQ96GZKn3r23fjQD6VgSho3FEaEBEQ/viewform?embedded=true"
        width="100%"
        height="1156"
        scrolling="no"
        style={{ border:"0px", margin: 0, overflow: 'hidden' }}
        title="Contact Us Form">
          Loading…
      </iframe>
    </div>
    </div>
    </div>
     <Footer/>

    </div>
  );
}

export default ContactUs;
