import React from 'react'
import Confidance1 from '../../assets/images/Confidance1.svg'
import Confidance5 from '../../assets/images/Confidance5.svg'
import Confidance3 from '../../assets/images/Confidance3.svg'
import Confidance4 from '../../assets/images/Confidance4.svg'
import Model1 from '../../assets/images/Model1.svg'
import Model2 from '../../assets/images/Model2.svg'
import Model3 from '../../assets/images/Model3.svg'
import Model4 from '../../assets/images/Model4.svg'
import Learning1 from '../../assets/images/Screenshot3 2024-03-28 at 5.17 1.png'
import Arrow3 from '../../assets/images/Arrow3.svg'

import chat from '../../assets/images/chat.png'

export default function Adapt() {
  return (
    <div id='adapt-bg' className='adapt-bg'>
        <div className='w-11/12 2xl:max-w-[1198px] mx-auto pt-10 md:pt-[116px] pb-5 md:pb-20 '>

        <div className="adapt-set w-full xl:w-[840px] h-auto px-5 sm:px-10 xl:px-0 py-5 sm:py-10 xl:py-0 xl:pl-[38px] xl:pr-[66px] xl:pb-[36px]  xl:pt-[54px]  text-center xl:text-start ">
                <h1 className='f-f-r-az uppercase text-blue-light text-base sm:text-xl '>Integrate</h1>

                <h2 className='f-f-r-no text-3xl sm:text-4xl lg:text-5xl text-white mt-4 md:leading-[74px]'>Batteries <span className='text-primary'> Included</span> </h2>

                <p className='f-f-r-az text-xs sm:text-sm lg:text-tiny text-white mt-6 leading-[26px]'>
                From integrations, data ingestion, vector databases, security controls, version management, and observability, BotDojo provides a comprehensive suite of tools to help developers build and deploy AI applications.

</p>
            </div>


            <div className="grid grid-cols-12  mt-10 gap-5">
                <div className="col-span-12 xl:col-span-8">
                    {/* inner grid start */}
                    <div className="grid grid-cols-12 gap-[18px]">
                        <div className="col-span-12 lg:col-span-6 text-center lg:text-start">
                        <div className="inline-flex items-center space-x-3 md:space-x-5 z-20 ">
                            <img src={Confidance1} className='w-[40px]  sm:w-[52px] h-[40px] sm:h-[52px] ' alt="" />
                            <h1 className='f-f-r-az text-base sm:text-lg md:text-xl uppercase text-blue-light '>Sync Data</h1>
                        </div>
                        <div className=" w-full mt-6">
                          <h1 className='f-f-r-az text-sm sm:text-tiny text-white leading-[26px]'></h1>
                        <p className='f-f-r-az text-sm sm:text-tiny text-white leading-[26px]'>
                            Unlock the full potential of LLMs by connecting your data sources. BotDojo provides a variety of integrations to help you ingest data and keep them up to date.
                        </p>
                        </div>
                        </div>

                        <div className="col-span-12 lg:col-span-6">
                        <div className="box-set3 h-auto xl:h-[363px] pb-[21px] pt-[34px] px-4 sm:px-6 2xl:px-9 text-center xl:text-start ">

<div className="inline-flex items-center space-x-5  ">
            <img src={Confidance5} className='w-[56px] h-[56px] ' alt="" />
            <h1 className='f-f-r-az text-xl uppercase text-blue-dark '>Enterprise-Grade</h1>
        </div>
        
        <p className='mt-5 xl:mt-[34px] f-f-r-az text-tiny text-[#000000] leading-[26px] '> 
        We provide granular security controls,  custom SSO, and flexible hosting options to meet your organization's needs.
         </p>

</div>
                        </div>
                    </div>
                    {/* inner grid end */}

                    <div className=" relative model-set w-full h-auto xl:h-[282px] mt-11 px-[38px] pb-11 pt-[29px]">

                    <div className="w-full  text-center xl:text-start  ">
            <div className="inline-flex items-center space-x-5 sm:space-x-[34px]  ">
                            <img src={Confidance3} className='w-[45px] sm:w-[51px] h-[45px] sm:h-[51px] ' alt="" />
                            <h1 className='f-f-r-az text-base sm:text-xl uppercase text-blue-light '>Integration</h1>
                        </div>

                        <p className='f-f-r-az text-sm sm:text-tiny text-white mt-5 sm:mt-[26px] leading-[26px] '>Seamlessly integrates with a variety of large language models to suit specific project needs.</p>
                        </div>

                        <div className="text-center xl:text-start">
                        <div className="sm:inline-flex items-center space-y-5 sm:space-y-0 sm:space-x-9 mt-10 sm:mt-12">
                            <img src={Model1} className='w-[101px] h-[29px] mx-auto sm:mx-0 ' alt="" />

                            <img src={Model2} className='w-[89px] h-[30px] mx-auto sm:mx-0  ' alt="" />

                            <img src={Model3} className='w-[91px] h-[31px] mx-auto sm:mx-0  ' alt="" />

                            <img src={Model4} className='w-[94px] h-[35px] mx-auto sm:mx-0  ' alt="" />
                        </div>
                        </div>
                            <img src={Arrow3} data-aos="fade-in" data-aos-delay="500" data-aos-duration="1000" className=' w-[286px] h-[941px] absolute left-[72px] top-[239px]  hidden 2xl:block '  />

                    </div>
                </div>

                <div className="col-span-12 xl:col-span-4">
                    <div className="learning-set w-full h-auto xl:h-[726px]">

                    <div className="px-4 sm:px-9 pt-10 xl:pt-9 text-center xl:text-start">

<div className="inline-flex items-center space-x-2 sm:space-x-5  ">
            <img src={Confidance4} className='w-[45px] sm:w-[56px] h-[45px] sm:h-[56px] ' alt="" />
            <h1 className='f-f-r-az text-base sm:text-xl uppercase text-blue-light '>Chat Interface</h1>
        </div>       
        <p className='f-f-r-az text-sm sm:text-tiny text-white mt-5 xl:mt-[32px] leading-[26px] '>Have your users interact with your AI applications using BotDojo Chat, Slack, or Microsoft Teams.</p>

        <img src={chat} className=' mt-10 xl:mt-16 w-full sm:w-[321px] h-auto md:h-[423px] mx-auto xl:mx-0' alt="" />
</div>

                    </div>
                </div>
            </div>


        </div>
      
    </div>
  )
}
