import React,{useState} from 'react'
import logo from '../../assets/images/Logo.svg'

export default function Header() {
  const [navbar, setnavbar] = useState(false)
  const [inIFream, setInIFream] = React.useState(false);
  React.useEffect(() => {
      if (window.location !== window.parent.location) {
          setInIFream(true);
      }
  }, []);
  if(inIFream){
    return null
  }
  return (

<nav className="bg-[#080D23] pt-[36px] ">
<div className=" w-11/12 2xl:max-w-[1198px]  mx-auto flex flex-wrap items-center justify-between   ">
  <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
      <img src={logo} className=" w-[170px] lg:w-[242px] h-auto lg:h-[58px]  " alt="" />
  </a>
  <button onClick={()=>setnavbar(!navbar)} data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm  rounded-lg lg:hidden  " aria-controls="navbar-default" aria-expanded="false">
      <span className="sr-only">Open main menu</span>
      <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#FFF" viewBox="0 0 17 14">
          <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
      </svg>
  </button>
  <div className= {`  ${navbar ? "block" : "hidden"} w-full lg:block lg:w-auto`} id="navbar-default">
    <ul className=" lg:items-center flex flex-col p-4 lg:p-0 mt-4 border border-gray-100 rounded-lg  lg:flex-row lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0  ">
    <li>
        <a href="/join-us" className="block py-2 px-3 text-white f-f-r-az  uppercase  text-sm  rounded  lg:p-0 " aria-current="page">HIRING</a>
      </li>
      <li>
        <a href="/about-us" className="block py-2 px-3 text-white f-f-r-az  uppercase  text-sm  rounded  lg:p-0 " aria-current="page">ABOUT US</a>
      </li>
    
      {/* <li>
        <a href="https://app.botdojo.com/hub/integrations" className="block py-2 px-3 text-white f-f-r-az  uppercase  text-sm rounded  lg:border-0  lg:p-0  ">Integrations</a>
      </li>
      <li>
        <a href="https://app.botdojo.com/hub/templates" className="block py-2 px-3 text-white f-f-r-az  uppercase  text-sm rounded  lg:border-0  lg:p-0  ">HUB</a>
      </li>
      <li>
        <a href="https://docs.botdojo.com/" className="block py-2 px-3 text-white f-f-r-az  uppercase  text-sm rounded  lg:border-0  lg:p-0  ">Docs</a>
      </li> */}
      <li>
      <a href="https://botdojo.com/contact-us" target="_parent" className=" py-2 px-3 text-white f-f-r-az w-full lg:w-[144px] h-[38px] bg-primary-dark  text-sm rounded lg:border-0 lg:p-0  flex justify-center items-center">
  Get Started
</a>
      </li>
    </ul>
  </div>
</div>
</nav>
  )
}
