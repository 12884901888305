import React from 'react'
import Header from './header'
import Hero from './Hero'
import Confidance from './Confidance'
import Adapt from './Adapt'
import Visual from './Visual'
import Footer from './Footer'
import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
    duration: 1200,
});
export default function Index() {
    const [inIFream, setInIFream] = React.useState(false);
    useEffect(() => {
        if (window.location !== window.parent.location) {
            setInIFream(true);
        }
    }, []);

    return (
        <div style={{overflowX:'hidden'}}>
        {!inIFream && <Header/>}
    
         <Hero/>
         <Confidance/>
         <Adapt/>

     
       <Visual/>
       
       
         <Footer/>

        </div>
    )
}
