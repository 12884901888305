import './App.css';
import Home from './components/Home/index';
import AboutUs from './components/AboutUs/index';
import Privacy from './components/Terms/privacy';
import Terms from './components/Terms/terms';
import ContactUs from './components/AboutUs/contact-us';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import JoinUs from './components/AboutUs/hiring';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
export const initGA = (trackingID) => {
  ReactGA.initialize('G-HHW3QMBBC6', {
    gaOptions: {
      cookieDomain: '.botdojo.com',  
      allowLinker: true,
    },
  });
  ReactGA.ga('require', 'linker');
  ReactGA.ga('linker:autoLink', ['botdojo.com', 'public.botdojo.com', 'www.botdojo.com']);
  
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};
const TRACKING_ID = "G-HHW3QMBBC6"; // Replace with your tracking ID


const history = createBrowserHistory();
function App() {
  useEffect(() => {
    initGA(TRACKING_ID);
    history.listen((location) => {
      logPageView();
    });
  }, []);
  //pages/privacy-policy
  return (
    <Router history={history}>
      <Routes>
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />

        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/join-us" element={<JoinUs />} />
        <Route path="/pages/privacy-policy" element={<Privacy />} />
        <Route path="/pages/terms" element={<Terms />} />

        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}
export default App;
