import React from 'react'
import Confidance3 from '../../assets/images/Confidance3.svg'
import Confidance1 from '../../assets/images/Confidance1.svg'
import Confidance5 from '../../assets/images/Confidance5.svg'

import visual1 from '../../assets/images/designer-screen.png'

export default function Visual() {
  return (
    <div id="visual" className='visual-bg pb-10 xl:pb-[151px]  pt-20 xl:pt-[200px] 2xl:pt-[290px]'>
      <div className=" w-11/12 2xl:max-w-[1198px]  mx-auto ">


        <div className="w-full xl:w-[840px] h-auto px-5 sm:px-10 xl:px-0 py-5 sm:py-10 xl:py-0 xl:pl-[39px] xl:pr-[99px] xl:pb-[23px]  xl:pt-[53px] ml-auto border border-primary rounded-2xl bg-blue-dark text-center xl:text-start ">
          <h1 className='f-f-r-az uppercase text-blue-light text-base sm:text-xl'>Collaborative Design</h1>

          <h2 className='f-f-r-no text-[30px] sm:text-4xl lg:text-5xl text-white mt-4'><span className='text-primary'>Visual </span>Design Studio</h2>

          <p className='f-f-r-az text-xs sm:text-sm lg:text-tiny text-white mt-6 leading-[26px]'>BotDojo's robust low-code solution harmonizes design, testing, and observability, enabling a more integrated and powerful AI first development environment.</p>
        </div>


        <div className="grid grid-cols-12 gap-5  mt-10 xl:mt-[62px]">

          <div className="col-span-12 xl:col-span-4">
            <div className="visual-set px-5 xl:px-0 xl:pr-[33px] xl:pl-9 py-5 xl:py-0 xl:pt-9 text-center xl:text-start h-auto xl:h-[296px] ">

              <div className="inline-flex items-center space-x-5 sm:space-x-[31px]  ">
                <img src={Confidance3} className='w-[45px] sm:w-[51px] h-[45px] sm:h-[51px] ' alt="" />
                <h1 className='f-f-r-az text-base sm:text-xl uppercase text-blue-light '>Starter Packs</h1>
              </div>

              <p className='f-f-r-az text-tiny text-white mt-5 xl:mt-[27px] leading-[26px] '>

                Get up to speed quickly using battle-tested Starter Packs for RAG workflows, Agents, Document Summarization and more.
                </p>
                {/* <div className="text-center mt-5 absolute bottom-5 left-1/2 transform -translate-x-1/2">
                  <a href="https://app.botdojo.com/hub/templates" target="_blank" className='text-primary underline'>Browse Starter Packs</a>
                </div> */}

          
            </div>

            <div className=" mt-10 xl:mt-[34px] h-auto xl:h-[340px] pb-[21px] pt-8 px-4 sm:px-9 2xl:px-[38px] text-center xl:text-start ">

              <div className="inline-flex items-center space-x-3 md:space-x-[31px] z-20 ">
                <img src={Confidance1} className='w-[40px]  sm:w-[52px] h-[40px] sm:h-[52px] ' alt="" />
                <h1 className='f-f-r-az text-base sm:text-lg md:text-xl uppercase text-blue-light '>Customizable</h1>
              </div>

              <p className='mt-5 xl:mt-7 f-f-r-az text-tiny text-white leading-[26px] '>Low-code, but not low power. Customize to your specific use case with the ability to execute your own custom Python or JavaScript.</p>
/
            </div>
          </div>

          <div className="col-span-12 xl:col-span-8">
            <div className="box-set3 h-auto xl:h-[610px] pb-[21px] pt-[34px] px-4 sm:px-6 2xl:px-9 text-center xl:text-start ">

              <div className="inline-flex items-center space-x-[31px]  ">
                <img src={Confidance5} className='w-[56px] h-[56px] ' alt="" />
                <h1 className='f-f-r-az text-xl uppercase text-blue-dark '>Accelerated <br className='sm:hidden' /> Development</h1>
              </div>

              <p className='mt-5 xl:mt-[34px] f-f-r-az text-tiny text-[#000000] leading-[26px] '>
                Rapidly prototype, test, and iterate on your AI applications.</p>


              <img src={visual1} className='max-w-full h-auto mt-5 md:mt-[46px] mx-auto xl:mx-0 xl:ml-auto' alt="" />

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
