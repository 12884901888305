import React from 'react'
import Footerimg from '../../assets/images/Footerimg.svg'

export default function Footer() {
  const [inIFream, setInIFream] = React.useState(false);
  React.useEffect(() => {
    if (window.location !== window.parent.location) {
        setInIFream(true);
    }
}, []);
if(inIFream){
  return null
}
  return (
    <div className='bg-blue-dark'>
      <div className='w-11/12 2xl:max-w-[1198px] mx-auto py-10 2xl:py-[90px]'>
        <img src={Footerimg} className='w-[200px] 2xl:w-[284px] h-auto 2xl:h-[70px] mx-auto xl:mx-0' alt="" />
        <div className='text-center mt-5'>
          <a href="/privacy-policy" className='text-white underline mr-5'>Privacy Policy</a>
          <a href="/terms" className='text-white underline'>Terms of Service</a>
        </div>
      </div>
    </div>
  )
}
