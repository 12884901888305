import React from 'react'
import Confidance1 from '../../assets/images/Confidance1.svg'
import Confidance2 from '../../assets/images/Screenshot 2024-03-28 at 5.17 1.png'
import Confidance3 from '../../assets/images/Confidance3.svg'
import Confidance4 from '../../assets/images/Confidance4.svg'
import Confidance5 from '../../assets/images/Confidance5.svg'
import Arrow1 from '../../assets/images/Group 3.svg'
import Arrow2 from '../../assets/images/aroow1.svg'
import EvalChart from '../../assets/images/evalchart.png'


export default function Confidance() {
  return (
    <div id='confidance' className='confidance-bg'>
      <div className=" w-11/12 2xl:max-w-[1198px]  mx-auto pb-12  pt-10 xl:pt-[342px]">
        <div data-aos="slide-left">
          <div className=" relative w-full xl:w-[840px] h-auto px-5 sm:px-10 xl:px-0 py-5 sm:py-10 xl:py-0 xl:pl-[38px] xl:pr-[100px] xl:pb-[23px]  xl:pt-[54px] ml-auto border border-primary rounded-2xl bg-blue-dark text-center xl:text-start ">
            <h1 className='f-f-r-az uppercase text-blue-light text-base sm:text-xl'>Evaluate & Monitor</h1>

            <h2 className='f-f-r-no text-3xl sm:text-4xl lg:text-5xl text-white mt-4'><span className='text-primary'> Confidence</span> in deployment</h2>

            <p className='f-f-r-az text-xs sm:text-sm lg:text-tiny text-white mt-6 leading-[26px]'>BotDojo gives you the confidence to launch your AI projects, ensuring they're tested, reliable, and ready for real-world action. </p>

            <img src={Arrow1} data-aos="fade-in" data-aos-delay="1500" data-aos-duration="1000" className=' hidden 3xl:block xl:block w-[228px] h-[277px] left-[-228px] bottom-[-95px] absolute  ' />
          </div>
        </div>
        <div data-aos="slide-right" >
          <div className="grid grid-cols-12 gap-5 xl:gap-[23px] mt-10 xl:mt-[93px]">
       
            <div className="col-span-12 xl:col-span-8">
              <div className="box-set2 w-full h-auto xl:h-[681px] rounded-[18px] text-center xl:text-start px-5 xl:px-0 xl:pl-[38px] pt-10 sm:pt-14 ">
                <div className="inline-flex items-center space-x-3 md:space-x-5 sm:space-x-[31px] z-20 ">
                  <img src={Confidance1} className='w-[40px]  sm:w-[52px] h-[40px] sm:h-[52px] ' alt="" />
                  <h1 className='f-f-r-az text-base sm:text-lg md:text-xl uppercase text-blue-light '>Risk Mitigation</h1>
                </div>
                <div className="mt-8 w-full xl:w-[615px]">
                  <h1 className='f-f-r-az text-sm sm:text-tiny text-white leading-[26px]'>Identify hallucinations and incorrect outputs; evaluate performance and avert regressions.  Choose from a variety of prebuilt evaluations or easily build your own.


                
                  {/* <div className="text-center mt-5 absolute bottom-5 left-1/2 transform -translate-x-1/2">
                    <a href="https://docs.botdojo.com/tutorials/measure-quality/" target="_blank" className='text-primary underline'>Browse Evaluations</a>
                  </div> */}
                  </h1>
                </div>
                <div className="flex justify-center pr-9 pt-10">
                  <img src={EvalChart} className='mx-auto' alt="" />
                </div>
              </div>


            </div>


            <div className="col-span-12 xl:col-span-4">
              <div className="xl:pl-9 pt-10 xl:pt-12 text-center xl:text-start">

                <div className="inline-flex items-center space-x-5  ">
                  <img src={Confidance4} className='w-[56px] h-[56px] ' alt="" />
                  <h1 className='f-f-r-az text-xl uppercase text-blue-light '>Human Feedback</h1>
                </div>
                <p className='f-f-r-az text-tiny text-white mt-5 xl:mt-[34px] leading-[26px] '>Collect insights and feedback from your users to improve the quality of your AI applications.</p>
              </div>

              <div className=" relative box-set3 mt-10 xl:mt-[54px] h-auto xl:h-[340px] pb-[21px] pt-8 px-4 sm:px-6 2xl:px-[38px] text-center xl:text-start ">

                <div className="inline-flex items-center space-x-5  ">
                  <img src={Confidance5} className='w-[56px] h-[56px] ' alt="" />
                  <h1 className='f-f-r-az text-xl uppercase text-blue-dark '>Measure Performance</h1>
                </div>

                <p className='mt-5 xl:mt-10 f-f-r-az text-tiny text-black leading-[26px] '>Compare how your applications perform using different language models and prompts.

                  {/* <div className="text-center mt-5 absolute bottom-5 left-1/2 transform -translate-x-1/2">
                    <a href="https://docs.botdojo.com/tutorials/measure-quality/" target="_blank" className='text-primary underline'>Learn More</a>
                  </div> */}
                </p>
                <img src={Arrow2} data-aos="fade-in" data-aos-delay="500" data-aos-duration="1000" className='hidden 2xl:block w-[237px] h-[819px] top-[338px] absolute left-[19px]   ' />
              </div>
            </div>
          </div>

          <div className="w-full xl:w-[756px] mt-10 xl:mt-[68px] text-center xl:text-start  ">
            <div className="inline-flex items-center space-x-5 sm:space-x-[34px]  ">
              <img src={Confidance3} className='w-[51px] h-[51px] ' alt="" />
              <h1 className='f-f-r-az text-lg sm:text-xl uppercase text-blue-light '>Business Integration and Acceptance</h1>
            </div>

            <p className='f-f-r-az text-sm sm:text-tiny text-white mt-[37px] leading-[26px] '>Dependable AI deployments foster trust and faster adoption, enhancing productivity and delivering benefits to your business operations more swiftly.</p>
          </div>

        </div>
      </div>

    </div>
  )
}
