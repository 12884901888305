import React from 'react';
import Header from '../Home/header'
import Footer from '../Home/Footer'
function JoinUs() {
  return (
    <div>
            

            
    <Header/>
    <div className='herobg py-10 xl:py-20 h-auto'>
       <div className="w-11/12 2xl:max-w-[1198px] mx-auto">
           <div className="text-center">
               <h1 className='text-set f-f-r-no text-4xl sm:text-5xl lg:text-6xl text-primary'>Join the Team!</h1>
             
           <div className="mt-10 text-center">

               <p className='text-white  mt-4'>
               Join us on a mission to revolutionize how businesses build and deploy AI applications powered by large language models. We're at the forefront of technology, seeking passionate individuals to push the boundaries of AI application development.
Work on groundbreaking AI projects.
Be part of a learning-focused, dynamic environment.
Collaborate with innovative and humorous team members.
Thrive in a fast-paced environment that focuses on shipping products.


               </p>
           </div>
           </div>
    <div className="py-10"></div>
    <div className="contact-us-page">
      <iframe 
        src="https://docs.google.com/forms/d/e/1FAIpQLSeUznrGOcXOMBTC5j5qfW5cenJH43O2BlYuBWyGtT-03vZZIA/viewform?embedded=true"
        width="100%"
        height="2156"
        scrolling="no"
        style={{ border:"0px", margin: 0, overflow: 'hidden' }}
        title="Join the Team!">
          Loading…
      </iframe>
    </div>
    </div>
    </div>
    <Footer/>
    </div>
  );
}

export default JoinUs;
