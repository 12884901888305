import React from 'react'
import Heroimg from '../../assets/images/Hero.svg'
import Card1 from '../../assets/images/Card1.svg'
import Card2 from '../../assets/images/Card2.svg'
import Card3 from '../../assets/images/Card3.svg'

export default function Hero() {
  return (
    <div className='herobg py-10 xl:py-0 xl:pt-[56px] h-auto xl:h-[1000px]'>
        <div className=" w-11/12 2xl:max-w-[1198px]  mx-auto">
            <div className="grid grid-cols-12 items-center mt-10 sm:mt-0 sm:gap-10 2xl:gap-[77px]  ">
   
                <div className="col-span-12 xl:col-span-5">
                <div data-aos="slide-up">
                    <h1 className='text-set f-f-r-no text-4xl sm:text-5xl lg:text-6xl text-white text-center xl:text-start'>AI <span className='text-primary'>enablement</span> platform</h1>

                    <div className="sm:inline-flex items-center mt-4 space-y-4 sm:space-y-0  sm:space-x-4 justify-center xl:justify-start w-full">
                        <button onClick={() => window.location.href='#visual'} className='w-full sm:w-[149px] h-9 rounded-[7px] border border-primary f-f-r-az text-sm text-white'>Design</button>

                        <button onClick={() => window.location.href='#confidance'} className='w-full sm:w-[137px] h-9 rounded-[7px] border border-primary f-f-r-az text-sm text-white'>Evaluate </button>

                        <button onClick={() => window.location.href='#deploy'} className='w-full sm:w-[102px] h-9 rounded-[7px] border border-primary f-f-r-az text-sm text-white'>Integrate</button>
                    </div>

                    <div className="w-full xl:w-[486px] text-center xl:text-start">

                    <h2 className='text-set f-f-r-az text-sm sm:text-tiny mt-10 text-primary-light leading-[26px]'>From idea to production, BotDojo provides tools for shipping AI Applications using Large Language Models.</h2>
                    </div>
                    <div className="relative mt-9 w-full sm:w-[191px] mx-auto xl:mx-0">
                    <a href="https://botdojo.com/contact-us" target="_parent" className='w-full h-[46px] rounded-[7px] bg-primary-dark f-f-sm-az text-base text-white inline-block text-center leading-[46px]'>Get Started</a>
                    </div>
              
                </div>
              
                </div>
                <div className="col-span-12 xl:col-span-7">
                    <img src={Heroimg} className='w-full h-auto md:h-[600px] lg:h-[663px]' alt="" />
                </div>
            </div>
            <div data-aos="slide-up">
            <div className="grid grid-cols-12 gap-5 mt-10 sm:mt-[84px]">
              
            <div className=" col-span-12 md:col-span-6 xl:col-span-4">
       
                    <div className="set-box w-full h-auto sm:h-[324px] rounded-[18px] px-5 sm:px-9 py-5 sm:py-8">
                        <div className="inline-flex items-center space-x-5 sm:space-x-[26px]">
                            <img src={Card1} className='w-[54px] sm:w-[64px] h-[54px] sm:h-[64px]' alt="" />
                            <h1 className='f-f-r-az text-xl sm:text-2xl text-blue sm:leading-9'>Collaborative Design</h1>
                        </div>

                        <h1 className='f-f-r-az text-sm sm:text-tiny text-blue-dark leading-[26px] mt-5'>Low-code tools for teams built specifically for LLM-powered applications.
                        
                     
                        
                        </h1>
                        <div className="text-center mt-5">
                            <a href="#visual" className='text-primary underline'>Learn More</a>
                        </div>


                    </div>
                   
                </div>

                <div className="col-span-12 md:col-span-6 xl:col-span-4">
                    <div className="set-box w-full h-auto sm:h-[324px] rounded-[18px] px-5 sm:px-9 py-5 sm:py-8">
                        <div className="inline-flex items-center space-x-5 sm:space-x-[26px]">
                            <img src={Card2} className='w-[54px] sm:w-[64px] h-[54px] sm:h-[64px] ' alt="" />
                            <h1 className='f-f-r-az text-xl sm:text-2xl text-blue sm:leading-9'>Evaluate & Monitor</h1>
                        </div>

                        <h1 className='f-f-r-az text-sm sm:text-tiny text-blue-dark leading-[26px] mt-5'>Evaluate your applications to identify and mitigate LLM hallucinations, biases, and block undesirable outputs from affecting your users.</h1>
                        <div className="text-center mt-5">
                            <a href="#confidance" className='text-primary underline'>Learn More</a>
                        </div>

                    </div>
                </div>

                <div className="col-span-12 xl:col-span-4">
                    <div className="set-box w-full h-auto sm:h-[324px] rounded-[18px] px-5 sm:px-9 py-5 sm:py-8">
                        <div className="inline-flex items-center space-x-5 sm:space-x-[26px]">
                            <img src={Card3} className='w-[54px] sm:w-[64px] h-[54px] sm:h-[64px] ' alt="" />
                            <h1 className='f-f-r-az text-xl sm:text-2xl text-blue sm:leading-9'>Integrate</h1>
                        </div>

                        <h1 className='f-f-r-az text-sm sm:text-tiny text-blue-dark leading-[26px] mt-5'>Seamlessly integrate into your existing applications, data sources, and processes.  Compatible with all major model providers.  </h1>
                        <div className="text-center mt-5">
                            <a href="#adapt-bg" className='text-primary underline'>Learn More</a>
                            </div>

                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}
