import React from 'react'
import Header from '../Home/header'
import Footer from '../Home/Footer'
import { useEffect } from 'react'
import AOS from 'aos'
import Heroimg from '../../assets/images/Hero.svg'
import Card1 from '../../assets/images/Card1.svg'
import Card2 from '../../assets/images/Card2.svg'
import Card3 from '../../assets/images/Card3.svg'
import LinkedIn from '../../assets/images/linkedin.png'
import 'aos/dist/aos.css'
AOS.init({
    duration: 1200,
});
export default function index() {
   

    return (
        <div>
            
        <Header/>
         <div className='herobg py-10 xl:py-20 h-auto'>
            <div className="w-11/12 2xl:max-w-[1198px] mx-auto">
                <div className="text-center">
                    <h1 className='text-set f-f-r-no text-4xl sm:text-5xl lg:text-6xl text-primary'>About Us</h1>
                    <p className='mt-4 text-white text-lg sm:text-xl lg:text-2xl'>  Our mission is to enable organizations to confidently leverage AI through comprehensive tools to build, deploy, and manage applications powered by Large Language Models.
            </p>
                </div>
               
                <div className="mt-10 sm:mt-20">
                    <h3 className='text-center text-set f-f-r-no text-4xl sm:text-5xl lg:text-6xl text-primary'>Leadership Team</h3>
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                        <div className="text-center">
                            <h3 className='text-white f-f-r-az text-xl sm:text-2xl'>Paul Henry</h3>
                            <a href="https://www.linkedin.com/in/pauldhenry/" target="_blank" rel="noopener noreferrer">
                            
                            <img src={require('../../assets/images/paul.jpeg')} alt="Paul Henry" className="mx-auto h-auto" style={{maxHeight: '250px'}}/>
                           </a>
                            <p className='text-white f-f-r-az text-md sm:text-lg  mt-4'>Founder & CEO</p>
                            <div className="flex justify-center mt-4">
                                <a href="https://www.linkedin.com/in/pauldhenry/" target="_blank" rel="noopener noreferrer">
                                    <img src={LinkedIn} className='w-[24px] h-[24px]' alt="LinkedIn" />
                                </a>
                            </div>
                        </div>
                      
                        <div className="text-center">
                            <h3 className='text-white f-f-r-az text-xl sm:text-2xl'>Jason Hodges</h3>
                            <a href="https://www.linkedin.com/in/hodge/" target="_blank" rel="noopener noreferrer">
                            <img src={require('../../assets/images/jason3.png')} alt="Jason Hodges" className="mx-auto h-auto" style={{maxHeight: '250px'}}/>
                            </a>
                            <p className='text-white f-f-r-az text-md sm:text-lg  mt-4'>Advisor/Investor</p>
                            <div className="flex justify-center mt-4">
                                <a href="https://www.linkedin.com/in/hodge/" target="_blank" rel="noopener noreferrer">
                                    <img src={LinkedIn} className='w-[24px] h-[24px]' alt="LinkedIn" />
                                </a>
                            </div>
                        </div>
                        <div className="text-center">
                            <h3 className='text-white f-f-r-az text-xl sm:text-2xl'>Nathan Jones</h3>
                            <a href="https://www.linkedin.com/in/nathan-jones-b998a/" target="_blank" rel="noopener noreferrer">
                           
                            <img src={require('../../assets/images/nathan2.png')} alt="Nathan Jones" className="mx-auto h-auto" style={{maxHeight: '250px'}}/>
                            </a>
                            <p className='text-white f-f-r-az text-md sm:text-lg  mt-4'>Advisor/Investor</p>
                         
                            <div className="flex justify-center mt-4">
                                <a href="https://www.linkedin.com/in/nathan-jones-b998a/" target="_blank" rel="noopener noreferrer">
                                    <img src={LinkedIn} className='w-[24px] h-[24px]' alt="LinkedIn" />
                                </a>
                            </div>
                        </div>
                      
           
                    </div>
                </div>
                <div className="mt-10 sm:mt-20">
                    <h3 className='text-center text-set f-f-r-no text-4xl sm:text-5xl lg:text-6xl text-primary'>Join Us</h3>
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                        <div className="text-center">
                            <h3 className='text-white f-f-r-az text-lg sm:text-xl'>AI Engineer</h3>
                            <p className='text-white f-f-r-az text-md'>We're looking for innovative AI Engineers to join our team.</p>
                        </div>
                        <div className="text-center">
                            <h3 className='text-white f-f-r-az text-lg sm:text-xl'>UI/UX Engineer</h3>
                            <p className='text-white f-f-r-az text-md'>Seeking creative UI/UX Engineers to design the future of AI interfaces.</p>
                        </div>
                        <div className="text-center">
                            <h3 className='text-white f-f-r-az text-lg sm:text-xl'>DevOps</h3>
                            <p className='text-white f-f-r-az text-md'>Join our DevOps team to streamline our AI development processes.</p>
                        </div>
                    </div>
                    <div className="mt-10 text-center">
                            <a href="/join-us" className='w-full sm:w-auto h-[46px] rounded-[7px] bg-primary-dark f-f-sm-az text-base text-white inline-block px-10 leading-[46px]'>Join Us</a>
                        </div>
                </div>
            </div>
        </div>
         <Footer/>

        </div>
    
    )
}
